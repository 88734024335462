import CurrencyFormat from "react-currency-format"
import { UncontrolledTooltip } from "reactstrap";
import utcToZonedTime from "date-fns-tz/utcToZonedTime";
import { getIANATimezoneStringForAbbreviation } from "helpers/utlis";
import { REPORTING_TIMEZONE } from "helpers/constants";
import { format } from 'date-fns';

export function delimitterHTMLformatter(value) {
  return (
    <CurrencyFormat
      displayType="text"
      decimalScale={2}
      value={(value === null) ? '0' : value}
      thousandSeparator
    />
  );
}

export const percentHTMLformatter = (value) => (
  <CurrencyFormat
    displayType="text"
    value={(value === null) ? '0' : value}
    suffix="%"
    decimalScale={2}
    fixedDecimalScale
    thousandSeparator
  />
);

export function defaultFormatter(cellContent) {
  return (
    <span>{cellContent ? cellContent : "--"}</span>
  )
}

export function defaultNumberFormatter(cellContent) {
  return (
    <span>{cellContent != null ? cellContent : 0}</span>
  )
}

export const timeDurationFormatter = (seconds) => {
  if (!seconds) {
    seconds = 0
  }
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = parseInt(seconds % 60);

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
}

export function onHoverPopUpTooltipFormatter(cellContent, row, colIndex, keyField) {
  return (
    <div className="text-truncate">
      <span id={`hover-tooltip-${keyField || ""}-${row?.id}-${colIndex}-formatter`}>{cellContent ? cellContent : "--"}</span>
      {
        cellContent && (
          <UncontrolledTooltip placement="left" target={`hover-tooltip-${keyField || ""}-${row?.id}-${colIndex}-formatter`}>
            {cellContent}
          </UncontrolledTooltip>
        )
      }
    </div>
  )
}

export const booleanFormatter = (cell, showTick = false) => {
  const iconClass = showTick
    ? (cell ? 'bx bx-check' : 'mdi mdi-close')
    : 'bx bxs-circle'

  return (
    <div className="d-inline-flex align-items-center justify-content-center">
      <i
        className={`${iconClass} font-size-1 ${cell ? 'text-success' : 'sfred'}`}
        style={showTick ? { fontSize: '1.2rem' } : {}}
      />
      <span className="ms-1 d-flex align-self-center fw-bold">
        {cell ? "Yes" : "No"}
      </span>
    </div>
  );
};


export const insuranceCarrierListFormatter = (list) => {
  return (
    <div>
      {list.map((item, index) => (
        <p key={index} style={{ margin: 0 }}>{item}</p>
      ))}
    </div>
  );
};

export function nameFormatter(cellContent, row) {
  return (
      <span>{row.first_name + " " + row.last_name}</span>
  )
}

export function locationFormatter(cellContent, row) {
  return (
      <span className='text-capitalize'>{(row?.city || "--") + ", " + (row?.state || "--")}</span>
  )
}

export function dateCreatedFormatter(cellContent, row) {
  return (
      <span>{cellContent ? format(utcToZonedTime(cellContent, getIANATimezoneStringForAbbreviation(REPORTING_TIMEZONE)), 'MM/dd/yyyy hh:mm aa') : "--"}</span>
  )
}
