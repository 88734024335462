import { ccLog } from "./utlis";

export const bsh_getUserAuthToken = () => {
  try {
    const userAuthToken = localStorage.getItem(STORAGE_KEY.AUTH_TOKEN);
    return userAuthToken;
  } catch (error) {
    console.error('Error getting user token:', error);
    return null;
  }
}

export const bsh_getFilters = (filters, field) => {
  return JSON.parse(localStorage.getItem(STORAGE_KEY.FILTERS.NAME))?.[filters]?.[field];
}

export const STORAGE_KEY = {
  AUTH_USER: 'authUser',
  AUTH_TOKEN: 'authToken',
  FILTERS: {
    NAME: 'filters',
    CAMPAIGN: 'campaign',
  },
  REDIRECT_PATH: 'redirectPath',
  AUTH_ADMIN_USER: 'authAdminUser',
  ADMIN_ACCESS: 'adminAccess'
}

export const bsh_setFilters = (filters, field, value) => {
  let filtersObj = JSON.parse(localStorage.getItem(STORAGE_KEY.FILTERS.NAME));
  if (!filtersObj) {
    let data = {
      [filters]: {
        [field]: [value]
      }
    }
    localStorage.setItem(STORAGE_KEY.FILTERS.NAME, JSON.stringify(data));
    return;
  } else {
    if (filtersObj?.[filters]) {
      filtersObj[filters][field] = value;
      localStorage.setItem(STORAGE_KEY.FILTERS.NAME, JSON.stringify(filtersObj));
    } else {
      filtersObj[filters] = {
        [field]: value
      }
      localStorage.setItem(STORAGE_KEY.FILTERS.NAME, JSON.stringify(filtersObj));
    }
  }
}

export const bsh_getAdminUserDetails = () => {
  let userInfo = null;
  try {
    userInfo = JSON.parse(localStorage.getItem(STORAGE_KEY.AUTH_ADMIN_USER));
  } catch (e) {
    ccLog('[WARN] Failed to parse admin token');
  }

  return {
    token: userInfo?.token
  }
}

export const bsh_isAdminUserDetailsPresent = () => {
  return !!bsh_getAdminUserDetails().token;
}

/**
 * Set admin user details
 * @param {object} userInfo Info about admin
 * @param {string} userInfo.token Auth token of admin
 */
export const bsh_setAdminUserDetails = ({ token }) => {
  localStorage.setItem(STORAGE_KEY.AUTH_ADMIN_USER, JSON.stringify({ token }));
}
/**
* Clear admin user details
*/
export const bsh_clearAdminUserDetails = () => {
  localStorage.removeItem(STORAGE_KEY.AUTH_ADMIN_USER);
  localStorage.removeItem("adminAccess")
}