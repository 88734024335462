// rootReducer.js
import { combineReducers } from "redux";
import authReducer from "./auth/login/reducer";
import Layout from "./layout/reducer";
import {forgetPassword, resetPassword} from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import authRegister from "./auth/register/reducer";
import fetchCampaigns from "./campaigns/reducer";
import campaignReducer from "./campaigns/reducer";
import currentUserReducer from "./user/reducer";
import accounts from "./accounts/reducer"
import Dashboard from "./dashboard/reducer"
import consentCertificateReducer from "./consentCertificates/reducer";
import replayReducer from "./replay/reducer";
import CertificateVault from "./certificate-vault/reducer";
import Clients from "./admin/clients/reducer";
import AdminUserSignup from "./admin/Authentication/signup/reducer"
import LoginAdminUser from "./admin/Authentication/login/reducer"

const rootReducer = combineReducers({
  authReducer,
  Layout,
  forgetPassword,
  resetPassword,
  Profile,
  authRegister,
  fetchCampaigns,
  campaignReducer,
  currentUserReducer,
  accounts,
  Dashboard,
  consentCertificateReducer,
  replayReducer,
  CertificateVault,
  Clients,
  AdminUserSignup,
  LoginAdminUser
});

export default rootReducer;
