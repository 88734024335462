import React from "react";
import PropTypes from "prop-types";
import { Form, Input, Label, FormFeedback, Col, Row, Alert } from "reactstrap";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import logoDark from "assets/images/logo-dark.png";
import { useFormik } from "formik";
import * as Yup from "yup"
import { AppConfiguration } from "config";
import * as CONSTANTS from "common/utils/data_validations"
import NumberFormat from "react-number-format";
import { ADMIN_SIGNUP_FIELD_NAMES, ADMIN_USER_DEPARTMENT_OPTIONS } from "helpers/constants";
import Select from 'react-select';
import { signupAdminUser } from "store/admin/Authentication/signup/actions";
import i18n_keys from "i18n_keys";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getFieldProps } from "helpers/utlis";

const AdminUserSignUp = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { loading, error } = useSelector(state => ({
        error: state.AdminUserSignup.error,
        loading: state.AdminUserSignup.loading,
    }))
    const { t } = useTranslation();

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: "",
            first_name: "",
            last_name: "",
            phone: "",
            extension: "",
            job_title: "",
            department: null,
            password: ""
        },
        validationSchema: Yup.object({
            email: Yup.string().email()
                .required(CONSTANTS.STR_INVALID_EMAIL)
                .test(
                    "Validate Email",
                    CONSTANTS.STR_INVALID_EMAIL,
                    function (value) {
                        if (value) {
                            if (CONSTANTS.invalidEmailRegex.test(value)) {
                                return this.createError({
                                    message: CONSTANTS.STR_INVALID_EMAIL_DIGITS_ERROR,
                                });
                            }
                            const emailExtension = value?.split('@');
                            return AppConfiguration.INVITE_ADMIN_INTERNAL_USERS.includes(emailExtension[1]?.toLowerCase());
                        }
                        return false;
                    },
                ),
            first_name: Yup.string()
                .required(CONSTANTS.STR_REQUIRED_FIRSTNAME)
                .matches(CONSTANTS.nameRegExp, CONSTANTS.STR_INVALID_FIRST_NAME)
                .min(2),
            last_name: Yup.string()
                .required(CONSTANTS.STR_REQUIRED_LASTNAME)
                .matches(CONSTANTS.nameRegExp, CONSTANTS.STR_INVALID_LAST_NAME)
                .min(2),
            phone: Yup.string()
                .matches(CONSTANTS.phoneRegExp, CONSTANTS.STR_INVALID_PHONE_NUMBER)
                .required(CONSTANTS.STR_REQUIRED_PHONE_NUMBER),
            // job_title: Yup.string()
            //     .required(CONSTANTS.STR_REQUIRED_FIELD)
            //     .min(2),
            // department: Yup.object().shape({
            //     label: Yup.string().required(),
            //     value: Yup.string().required(),
            // }),
            password: Yup.string()
                .required(CONSTANTS.STR_REQUIRED_PASSWORD)
                .matches(CONSTANTS.passwordRegExp, CONSTANTS.STR_PASSWORD_POLICY_STR)
        }),
        onSubmit: values => {
            const data = { admin_user: { ...values, department: values?.department?.value || null } }
            dispatch(signupAdminUser(data, navigate))
        },
    })

    return (
        <React.Fragment>
            <div className="account-pages my-5 pt-sm-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-9">
                            <div className="card overflow-hidden mt-2">
                                <div className="bg-white">
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <div className="text-center p-4">
                                                <img src={logoDark} height="70" alt="Logo" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body pt-0 pb-5 ms-3 me-3">
                                    <Form className="form-horizontal"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}>
                                        {error && error ?
                                            <Alert color="danger">{error}</Alert> : null}
                                        <div className="text-dark">
                                            <p className="font-weight-semibold text-success fs-18">Account Information</p>
                                        </div>
                                        <Row xl={12}>
                                            <Col xl={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label font-weight-semibold">First Name</Label>
                                                    <Input
                                                        {...getFieldProps(ADMIN_SIGNUP_FIELD_NAMES.FIRST_NAME, validation, { type: ADMIN_SIGNUP_FIELD_NAMES.TEXT }, ["name", "type", "onChange", "onBlur", "value", "invalid"])}
                                                    />
                                                    {getFieldProps(ADMIN_SIGNUP_FIELD_NAMES.FIRST_NAME, validation, {}, ["feedback"]).feedback}
                                                </div>
                                            </Col>
                                            <Col xl={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label font-weight-semibold">Last Name</Label>
                                                    <Input
                                                        {...getFieldProps(ADMIN_SIGNUP_FIELD_NAMES.LAST_NAME, validation, { type: ADMIN_SIGNUP_FIELD_NAMES.TEXT }, ["name", "type", "onChange", "onBlur", "value", "invalid"])}
                                                    />
                                                    {getFieldProps(ADMIN_SIGNUP_FIELD_NAMES.LAST_NAME, validation, {}, ["feedback"]).feedback}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row xl={12}>
                                            <Col xl={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label font-weight-semibold">Phone</Label>
                                                    <NumberFormat
                                                        name="phone"
                                                        format="###-###-####"
                                                        type="text"
                                                        mask="_"
                                                        className={validation.touched.phone && validation.errors.phone ? "is-invalid form-control" : "form-control"}
                                                        onValueChange={(values) => {
                                                            const { value } = values;
                                                            validation.setFieldValue('phone', value);
                                                        }}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.phone || ""}
                                                        invalid={validation.touched.phone && validation.errors.phone ? "true" : "false"}
                                                        aria-invalid={validation.touched.phone && validation.errors.phone ? "true" : "false"}
                                                    />
                                                    {validation.touched.phone &&
                                                        validation.errors.phone ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.phone}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col xl={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label font-weight-semibold">Job Title</Label>
                                                    <Input
                                                        {...getFieldProps(ADMIN_SIGNUP_FIELD_NAMES.JOB_TITLE, validation, { type: ADMIN_SIGNUP_FIELD_NAMES.TEXT }, ["name", "type", "onChange", "onBlur", "value", "invalid"])}
                                                    />
                                                    {getFieldProps(ADMIN_SIGNUP_FIELD_NAMES.JOB_TITLE, validation, {}, ["feedback"]).feedback}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row xl={12}>
                                            <Col xl={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label font-weight-semibold">Extension</Label>
                                                    <Input
                                                        {...getFieldProps(ADMIN_SIGNUP_FIELD_NAMES.EXTENSION, validation, { type: ADMIN_SIGNUP_FIELD_NAMES.TEXT }, ["name", "type", "onChange", "onBlur", "value", "invalid"])}
                                                    />
                                                    {getFieldProps(ADMIN_SIGNUP_FIELD_NAMES.EXTENSION, validation, {}, ["feedback"]).feedback}
                                                </div>
                                            </Col>
                                            <Col xl={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label font-weight-semibold">Department</Label>
                                                    <Select
                                                        menuPortalTarget={document.body}
                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                        options={ADMIN_USER_DEPARTMENT_OPTIONS}
                                                        classNamePrefix="select2-selection"
                                                        onChange={e => {
                                                            let event = {
                                                                target: {
                                                                    name: "department",
                                                                    value: e,
                                                                },
                                                            }
                                                            validation.handleChange(event)
                                                        }}
                                                        onBlur={() => {
                                                            validation.handleBlur({
                                                                target: { name: "department" },
                                                            })
                                                        }}
                                                        value={validation.values.department}
                                                        invalid={
                                                            validation.touched.department &&
                                                                validation.errors.department
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.department &&
                                                        validation.errors.department ? (
                                                        <small className="text-danger">
                                                            {CONSTANTS.STR_REQUIRED_FIELD}
                                                        </small>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row xl={12}>
                                            <Col xl={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label font-weight-semibold">Email</Label>
                                                    <Input
                                                        {...getFieldProps(
                                                            ADMIN_SIGNUP_FIELD_NAMES.EMAIL,
                                                            validation,
                                                            { id: ADMIN_SIGNUP_FIELD_NAMES.EMAIL, className: "form-control", type: ADMIN_SIGNUP_FIELD_NAMES.EMAIL },
                                                            ["name", "id", "className", "type", "onChange", "onBlur", "value", "invalid"]
                                                        )}
                                                    />
                                                    {getFieldProps(
                                                        ADMIN_SIGNUP_FIELD_NAMES.EMAIL,
                                                        validation,
                                                        { id: ADMIN_SIGNUP_FIELD_NAMES.EMAIL, className: "form-control", type: ADMIN_SIGNUP_FIELD_NAMES.EMAIL },
                                                        ["feedback"] // Feedback only
                                                    ).feedback}
                                                </div>
                                            </Col>
                                            <Col xl={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label font-weight-semibold">Password</Label>
                                                    <Input
                                                        {...getFieldProps(
                                                            ADMIN_SIGNUP_FIELD_NAMES.PASSWORD,
                                                            validation,
                                                            { type: ADMIN_SIGNUP_FIELD_NAMES.PASSWORD, placeholder: "" },
                                                            ["name", "type", "onChange", "onBlur", "value", "invalid"]
                                                        )}
                                                    />
                                                    {getFieldProps(
                                                        ADMIN_SIGNUP_FIELD_NAMES.PASSWORD,
                                                        validation,
                                                        { type: "password", placeholder: "" },
                                                        ["feedback"] // Feedback only
                                                    ).feedback}
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="mt-3 d-grid">
                                            <button className="btn btn-primary btn-block" type="submit">Register</button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                            <div className="mt-5 text-center">
                                <p className='text-white'>
                                    Already have an account ?
                                    <Link to="/login" className="fw-medium text-sfblue"> Login</Link>
                                </p>
                                <p className='text-white'>
                                    ©  {new Date().getFullYear()} {AppConfiguration.REACT_APP_PRODUCT_NAME}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};


export default AdminUserSignUp;
