import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-loading-skeleton/dist/skeleton.css';
import i18n_keys from 'i18n_keys';
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  UncontrolledTooltip
} from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit'
import SimpleBar from "simplebar-react"
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import ClientBreadCrumb from 'components/common/ClientBreadCrumb';
import { getClients, postAdminViewClientLogin } from 'store/admin/clients/actions';
import { columns, defaultSorted } from "utils/ClientsColumns";
import { handleSessionTimeOut } from "utils/common_utils";
import filterFactory from "react-bootstrap-table2-filter";
import LoadingSkeleton from 'components/common/LoadingSkeleton';
import _ from 'lodash';
import { customSortCaret } from "helpers/bootstrap_table_helper";
import { convertStringifiedNullToNull, KEYBOARD_PRESS_CODES } from "helpers/constants";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

const Clients = () => {
  const [selectedBusinessName, setSelectedBusinessName] = useState('');
  const [clientsList, setClientsList] = useState([]);
  const [companyFilter, setCompanyFilter] = useState('')

  const dispatch = useDispatch();
  let queryParams = {
    page_number: 1
  };

  const { loading, error, last_page_fetched, total_number_of_pages, admin_manage_clients
  } = useSelector(state => ({
    loading: state.Clients.admin_manage_clients_loading,
    error: state.Clients.admin_manage_clients_error,
    last_page_fetched: state.Clients.last_page_fetched,
    total_number_of_pages: state.Clients.total_number_of_pages,
    admin_manage_clients: state.Clients.admin_manage_clients
  }));

  useEffect(
    () => handleSessionTimeOut(error),
    [error],
  );

  const navigate = useNavigate();
  const getSearchParams = () => {
    const filterModal = {}
    filterModal.company_name_value = selectedBusinessName
    const filterModalData = convertStringifiedNullToNull(filterModal)
    return selectedBusinessName?.length ? queryParams.filters = JSON.stringify(filterModalData || {}) : null
  }

  const fetchNextBatch = (last_page_fetched = 0) => {
    queryParams.page_number = last_page_fetched + 1
    const filters = getSearchParams()
    !loading && dispatch(getClients({ queryParams: { ...queryParams, filters } }));

  }

  useEffect(() => {
    queryParams.page_number = 1
    !loading && dispatch(getClients({ queryParams: queryParams }));
  }, []);

  useEffect(() => {
    setClientsList(admin_manage_clients)
  }, [admin_manage_clients])

  // For Option Formatter
  function optionsFormatter(cellContent, row) {
    let row_id = row?.id;
    try {
      // Handling whitespaces in id
      row_id = (row?.id?.toString()).replace(/\s/g, '-');
    } catch (e) {
      row_id = '';
    }
    return (
      <div className="d-flex gap-3 justify-space-evenly">
        {(<>
          <span className='align-self-center'>
            <a onClick={() => { onClickUserLogin(row) }}>
              <i id={`login-icon${row_id}`} className="bx bx-log-in font-size-20 text-blue" />
              <UncontrolledTooltip
                placement="top"
                target={`login-icon${row_id}`}
              >
                Login
              </UncontrolledTooltip>
            </a>
          </span>

        </>)}
      </div>
    );
  }

  const accountIdAndCompanyNameColumns = [
    {
      dataField: 'accounts[0].id',
      text: 'Account ID',
      sort: true,
      columnName: 'account_id',
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex gap-3 justify-space-evenly text-blue" id='client-user-account-id'>
            {<span className='text-sfblue d-flex align-items-center'>
              <a onClick={() => onClickUserLogin(row)} id='admin-clients-page-client-user-id'>
                {cellContent}
              </a>
            </span>}
          </div>
        );
      }
    },
    {
      dataField: "accounts[0].company_name",
      text: "Company Name",
      sort: true,
      columnName: 'company_name',
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex gap-3 justify-space-evenly text-blue">
            {(<span className='text-sfblue align-self-center'>
              <a onClick={() => onClickUserLogin(row)}>
                {cellContent}
              </a>
            </span>)
            }
          </div>
        );
      }
    }
  ]

  const optionColumns = {
    dataField: 'options',
    text: 'Options',
    sort: false,
    formatter: optionsFormatter,
  };

  const onClickUserLogin = (user) => {
    dispatch(postAdminViewClientLogin({ user: { email: user.email }, history: navigate, accountId: user.accounts[0].id }))
  }

  const handleSearchCompanyName = (e) => {
    if (e.code == KEYBOARD_PRESS_CODES.ENTER) {
      getSearchParams()
      setCompanyFilter(selectedBusinessName)
      !loading && dispatch(getClients({ queryParams: queryParams }));
    }
  }

  const handleRemoveSearch = () => {
    setCompanyFilter('');
    setSelectedBusinessName('');
    queryParams.page_number = 1
    dispatch(getClients({ queryParams: queryParams }));
  }

  const { t } = useTranslation();

  return (
    <div
      className="page-content" id="page-content-wrapper">
      <MetaTags>
        <title>{t(i18n_keys.ADMIN_CLIENT) + t(i18n_keys.PAGE_TITLE_SUFFIX)}</title>
      </MetaTags>
      <Container fluid>
        <ClientBreadCrumb title="Clients" loadingState={loading} copyUrl={true} userCount={clientsList.length || 0} userCountOption={true} backOption={false} />

        <Row className="mt-3">
          <Col className="col-12">
            <Card>
              <CardBody>
                <ToolkitProvider
                  keyField="id"
                  // If we hardcode any of the field's hidden value then only customize columns works
                  columns={[{ dataField: 'dummy', hidden: true }, ...accountIdAndCompanyNameColumns, ...columns, optionColumns]}
                  data={clientsList || []}
                  exportCSV={{
                    exportAll: false,
                  }}
                >
                  {(toolkitProps) => (
                    <React.Fragment>
                      <Form onSubmit={(e) => e.preventDefault()}>
                        <Row className="">

                          <Col lg="3" className='align-self-end'>
                            <FormGroup className="mb-0">
                              <div className="search-box me-2">
                                <div className="position-relative">
                                  <Input
                                    className="rounded box-w"
                                    value={selectedBusinessName}
                                    placeholder="Search Company Name"
                                    onChange={(e) => {
                                      setSelectedBusinessName(e.target.value);
                                    }}
                                    onKeyDown={(e) => {
                                      handleSearchCompanyName(e)
                                    }}
                                  />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </FormGroup>
                          </Col>

                          {companyFilter.length ? <Col xl="auto" className="align-self-center">
                            <div className="d-flex flex-wrap align-items-center">

                              <div className={`mx-2 mb-1 selected_filter`}>
                                <div className="d-flex align-items-center">
                                  <span>Company Name: {companyFilter}</span>
                                  <a className={`ms-2 font-size-18`} onClick={() => { handleRemoveSearch() }} style={{ cursor: "pointer" }}>
                                    <span aria-hidden="true">&times;</span>
                                  </a>
                                </div>
                              </div>

                            </div>
                          </Col> : null}

                          <Col md="auto" className='ms-auto'>
                            <FormGroup className="mb-0 float-end">
                              <div id='new_campaign_button'>
                                <Link
                                  className={`btn btn-primary`}
                                  to={'/register'}
                                >
                                  <i className="bx bx-plus font-size-14 align-middle me-2" />
                                  Add New Client
                                </Link>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Form>

                      <Row className='table-overflow-auto'>
                        <div className="table-responsive">
                          <SimpleBar style={{ height: '60vh', overflow: 'auto' }}>
                            <Col>
                              {(loading && !clientsList?.length ? (
                                <LoadingSkeleton rows={8} cols={6} />
                              ) : (
                                <BootstrapTable
                                  keyField="id"
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  data={clientsList || []}
                                  classes={
                                    "table align-middle table-nowrap text-black thead-h50"
                                  }
                                  headerClasses=" custom-table-header text-black"
                                  rowClasses="tr-hover"
                                  headerWrapperClasses={"thead-light"}
                                  noDataIndication={!loading && "You currently don't have any users"}
                                  filter={filterFactory()}
                                  sort={{ sortCaret: customSortCaret }}
                                  {...toolkitProps.baseProps}
                                />
                              ))}
                            </Col>
                          </SimpleBar>
                        </div>
                      </Row>
                      <Row className='mt-3'>
                        {!loading && <Col xl='auto'>
                          {last_page_fetched} out of {total_number_of_pages == 0 ? 1 : total_number_of_pages} page(s)
                        </Col>}
                        <Col xl='auto' className='ms-auto'>
                          {last_page_fetched < total_number_of_pages && <button className='btn btn-primary'
                            onClick={() => fetchNextBatch(last_page_fetched)}>{loading ? 'Please wait...' : 'Load More'}</button>}
                        </Col>
                      </Row>

                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* Data table ends */}
      </Container>
    </div>
  )
}

export default Clients;