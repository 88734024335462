import { ERR_DEVISE_SIGNATURE_VERIFICATION_FAILED } from "./constants"
import * as CONSTANTS from "../common/utils/data_validations"

export const getQueryParamsFromObject = (query_params, skip_undefined = false) => {
  if (!query_params) {
    return ""
  }

  const query_string_arr = []
  for (const param in query_params) {
    if (skip_undefined && (query_params[param] === null || query_params[param] === undefined)) {
      continue;
    }
    const query_string = `${encodeURIComponent(param)}=${encodeURIComponent(
      query_params[param]
    )}`
    query_string_arr.push(query_string)
  }
  return query_string_arr.join("&")
}

export const sfGenericRequest = async (url, method, data) => {
  const response = await sf_fetch(url, {
    method,
    body: JSON.stringify(data.body),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: data.token,
    },
  })

  const responseText = await responseErrorChecker(response)
  // Handle success
  return JSON.parse(responseText).data
}

export const SfPostRequest = async (url, data) => {
  const response = await sf_fetch(url, {
    method: "POST",
    body: JSON.stringify(data.payload.payload),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: data?.token
    },
  })

  const responseText = await responseErrorChecker(response)
  // Handle success
  return JSON.parse(responseText).data
}

//Same as above but return additional info also(outside data)
export const genericGetRequest_2 = async (url, query_params, headers) => {
  const query_string = getQueryParamsFromObject(query_params, true)
  const response = await sf_fetch(`${url}?${query_string}`, {
    method: "GET",
    headers,
  })

  const responseText = await responseErrorChecker(response)
  return JSON.parse(responseText)
}

export const sf_fetch = async (resource, options = {}) => {
  const extra_headers = (['staging', 'production'].includes(process.env.NODE_ENV)) ? {} : {
    'ngrok-skip-browser-warning': true
  };

  let forward_opts = {
    ...(options || {}),
    headers: {
      ...(options?.headers || {}),
      ...extra_headers,
    }
  }

  return await fetch(resource, forward_opts);
}

export const responseErrorChecker = async (response) => {
  if (response.status === 500) {
    throw 'Internal Server Error';
  }

  const responseText = await response.text();
  const parsedResponseText = safeJSONParse(responseText, null);
  let message = '';
  // handle delete requests which don't return a response
  if (response.status === 204) {
    return responseText;
  }

  if (response.status === 401 && parsedResponseText?.errors?.code === ERR_DEVISE_SIGNATURE_VERIFICATION_FAILED) {
    window.location.replace('/login?session_expired=1')
  }

  if (typeof JSON.parse(responseText).errors !== 'undefined') {
    if (typeof JSON.parse(responseText).errors.detail !== 'undefined') {
      message = Object.keys(JSON.parse(responseText).errors.detail)[0];
      message = `${message
        } ${JSON.parse(responseText).errors.detail[message].join(',')}`;
      throw message;
    } else if (typeof JSON.parse(responseText).errors.code !== 'undefined') {
      throw CONSTANTS.errorMessages[JSON.parse(responseText).errors.code] || 'Something went wrong. Please try again later... ';
    }
  }

  return responseText;
};

export const safeJSONParse = (str, fallback) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return fallback;
  }
}

/**
 * Makes a generic GET request to the specified URL.
 *
 * @param {string} url - The URL to send the GET request to.
 * @param {Object} headers - Additional headers to include in the request.
 * @param {Object} payload - The data to be sent in the request body.
 * @param {Boolean} extra_params - Whether to skip undefined values in the payload.
 * @returns {Promise} A Promise that resolves to the JSON response from the server.
 */
export const genericGetRequest = async (url, payload, headers = {}, extra_params) => {
  try {
    let user = localStorage.getItem("authToken");
    const queryString = getQueryParamsFromObject(payload)
    const URL = `${url}?${queryString}`;
    const response = await fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: user,
        ...headers,
      },
    });
    const responseText = await responseErrorChecker(response);
    if (extra_params) {
      return JSON.parse(responseText)
    }
    return JSON.parse(responseText).data
  } catch (error) {
    const responseText = await responseErrorChecker(error)
    return JSON.parse(responseText).data
  }
}

/**
 * Makes a generic POST request to the specified URL.
 *
 * @param {string} url - The URL to send the POST request to.
 * @param {Object} headers - Additional headers to include in the request.
 * @param {Object} payload - The data to be sent in the request body.
 * @param {Boolean} extra_params - Whether to skip undefined values in the payload.
 * @returns {Promise} A Promise that resolves to the JSON response from the server.
 */
export const genericPostRequest = async (url, payload, headers = {}, extra_params) => {
  try {
    let user = localStorage.getItem("authToken");
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: user,
        ...headers,
      },
      body: JSON.stringify(payload),
    });
    const responseText = await responseErrorChecker(response);
    if (extra_params) {
      return JSON.parse(responseText)
    }
    return JSON.parse(responseText).data
  } catch (error) {
    const responseText = await responseErrorChecker(error)
    return JSON.parse(responseText).data
  }
}

/**
* Makes a generic POST request to the specified URL.
*
* @param {string} url - The URL to send the POST request to.
* @param {Object} headers - Additional headers to include in the request.
* @param {Object} payload - The data to be sent in the request body.
* @param {Boolean} extra_params - Whether to skip undefined values in the payload.
* @returns {Promise} A Promise that resolves to the JSON response from the server.
*/

export const genericPostRequest_2 = async (url, payload, headers = {}, extra_params) => {
  try {
    let user = localStorage.getItem("authToken");
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: user,
        ...headers,
      },
      body: JSON.stringify(payload),
    });
    const responseText = await responseErrorChecker(response);
    if (extra_params) {
      return JSON.parse(responseText)
    }
    return JSON.parse(responseText)
  } catch (error) {
    const responseText = await responseErrorChecker(error)
    return JSON.parse(responseText)
  }
}

/**
 * Makes a generic PUT request to the specified URL.
 *
 * @param {string} url - The URL to send the PUT request to.
 * @param {Object} headers - Additional headers to include in the request.
 * @param {Object} payload - The data to be sent in the request body.
 * @param {Boolean} extra_params - Whether to skip undefined values in the payload.
 * @returns {Promise} A Promise that resolves to the JSON response from the server.
 */
export const genericPutRequest = async (url, payload, headers = {}, extra_params) => {
  try {
    let user = localStorage.getItem("authToken");
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: user,
        ...headers,
      },
      body: JSON.stringify(payload),
    });
    const responseText = await responseErrorChecker(response);
    if (extra_params) {
      return JSON.parse(responseText)
    }
    return JSON.parse(responseText).data
  } catch (error) {
    const responseText = await responseErrorChecker(error)
    return JSON.parse(responseText).data
  }
}

/**
 * Makes a generic DELETE request to the specified URL.
 *
 * @param {string} url - The URL to send the DELETE request to.
 * @param {Object} headers - Additional headers to include in the request.
 * @param {Object} payload - The data to be sent in the request body.
 * @param {Boolean} extra_params - Whether to skip undefined values in the payload.
 * @returns {Promise} A Promise that resolves to the JSON response from the server.
 */
export const genericDeleteRequest = async (url, payload, headers = {}, extra_params) => {
  try {
    let user = localStorage.getItem("authToken");
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: user,
        ...headers,
      },
      body: JSON.stringify(payload),
    });
    const responseText = await responseErrorChecker(response);
    if (extra_params) {
      return JSON.parse(responseText)
    }
    return JSON.parse(responseText).data
  } catch (error) {
    const responseText = await responseErrorChecker(error)
    return JSON.parse(responseText).data
  }
}

export const postJwtForgetPwd = async (url, data) => {
  const response = await sf_fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: "",
    },
  })
  let message = ""

  switch (response.status) {
    case 500:
      message = "Internal Server Error. Please try again later"
      break
    case 400:
      message = "Account already exist, please login"
      break
    case 401:
      message = "Invalid credentials"
      break
    case 404:
      message = "Please check your email"
      break
    default:
      message = "Sorry, we are unable to resolve this. Please try again later"
  }
  if (response.status !== 200) {
    throw message
  }
  const token = response.headers.get("Authorization")
  const responseText = await response.text()
  return {
    user: {
      username: JSON.parse(responseText).first_name,
      email: JSON.parse(responseText).email,
      token,
      id: JSON.parse(responseText).id,
    },
  }
}

// postResetPwd
export const postJwtResetPwd = async (url, data) => {
  const response = await sf_fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
  const responseText = await responseErrorChecker(response)
  return JSON.parse(responseText).data
}

export const postJwtLogin = async (url, data) => {
  const response = await sf_fetch(url, {
    method: "POST",
    body: JSON.stringify({ user: data }),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: data?.token || "",
    },
  })
  let responseJson = null
  try {
    responseJson = await response.json()
  } catch (e) { }

  const responseText = await responseErrorChecker(response);
  if (response.status !== 200) {
    throw JSON.parse(responseText)
  }
  const token = response.headers.get("Authorization")
  return { data: { ...responseJson.data, token } }
}

export const postJwtAdminLogin = async (url, data) => {
  const response = await sf_fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: data?.token || "",
      "is-master": data?.is_master || "",
    },
  })

  let responseJson = null
  try {
    responseJson = await response.json()
  } catch (e) { }

  let message = ""

  switch (response.status) {
    case 500:
      message = "Internal Server Error. Please try again later"
      break
    case 400:
      message = "Account already exist, please login"
      break
    case 401:
      message = responseJson?.errors?.title || "Invalid credentials"
      break
    case 403:
      message = "User account inactive, please contact your admin"
      break
    case 404:
      message = responseJson?.errors?.title || "Invalid credentials"
      break
    case 422:
      message = responseJson?.errors?.title
      break
    default:
      message = "Sorry, we are unable to resolve this. Please try again later"
  }
  if (response.status !== 200) {
    throw message
  }
  const token = response.headers.get("Authorization")
  return { data: { ...responseJson.data, token } }
}

export const getCurrentUser = async (url, data) => {
  const response = await sf_fetch(url, {
    method: "GET",
    headers: {
      Authorization: data.token,
    },
  })

  const responseText = await responseErrorChecker(response)
  // Handle success
  return JSON.parse(responseText).data
}

//Using the same function for registering admin which is used in IO admin signup
export const postJwtRegister = async (url, data) => {
  const response = await sf_fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })

  // Check for response status first
  const { status } = response
  if (status === 500) {
    throw "Internal Server Error"
  }

  let responseText = await response.text()

  let message = ""
  if (typeof JSON.parse(responseText).errors !== "undefined") {
    if (typeof JSON.parse(responseText).errors.detail !== "undefined") {
      message = Object.keys(JSON.parse(responseText).errors.detail)[0]
      message = `${message} ${JSON.parse(responseText).errors.detail[
        message
      ].join(",")}`
      throw message
    } else if (
      typeof JSON.parse(responseText).errors.detail_unparsed !== "undefined"
    ) {
      message = Object.keys(JSON.parse(responseText).errors.detail_unparsed)[0]
      throw (
        JSON.parse(responseText).errors.detail_unparsed[message] ||
        "Please check card details"
      )
    } else if (typeof JSON.parse(responseText).errors.title !== "undefined") {
      message = JSON.parse(responseText).errors.title
      throw message || "Please check card details"
    } else if (typeof JSON.parse(responseText).errors.code !== "undefined") {
      throw CONSTANTS.errorMessages[JSON.parse(responseText).errors.code]
    }
  }

  // Handle success
  return responseText
}