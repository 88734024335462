import { takeEvery, put, call } from "redux-saga/effects"

import { SIGNUP_ADMIN_USER } from "./actionTypes";
import { signupAdminUserSuccess, signupAdminUserError } from "./actions";

import { AppConfiguration } from "config";

import { postJwtRegister } from "helpers/backend_helper";

function* signUpAdminUser({ payload: { user, history } }) {
  try {
    const response = yield call(
      postJwtRegister,
      `${AppConfiguration.RAILS_BACKEND_URL}/api/v1/admin_signup`,
      user)
    yield put(signupAdminUserSuccess(response))

    history("/login?flash_message_type=success&flash_message_text=Registration successful, please login to proceed.")
  } catch (error) {
    yield put(signupAdminUserError(error))
  }
}

export default function* signUpAdminUserSaga() {
  yield takeEvery(SIGNUP_ADMIN_USER, signUpAdminUser)
}
