import {
  SIGNUP_ADMIN_USER,
  SIGNUP_ADMIN_USER_SUCCESS,
  SIGNUP_ADMIN_USER_ERROR
} from "./actionTypes"


export const signupAdminUser = (user, history) => {
  return {
    type: SIGNUP_ADMIN_USER,
    payload: { user, history },
  }
}

export const signupAdminUserSuccess = user => {
  return {
    type: SIGNUP_ADMIN_USER_SUCCESS,
    payload: user,
  }
}

export const signupAdminUserError = error => {
  return {
    type: SIGNUP_ADMIN_USER_ERROR,
    payload: error,
  }
}