import React from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  Card, CardBody, Row, Table,
} from 'reactstrap';
import 'react-loading-skeleton/dist/skeleton.css';
import PropTypes from 'prop-types';

function LoadingSkeleton(props) {
  return (
    <Row>
      <Card>
        <CardBody>
          <Table>
            <tbody>
              {[...Array(props.rows)].map((elementInArray, index) => (
                <tr key={`loading-skeleton-row-${index}`}>
                  {[...Array(props.cols)].map((elementInArray, index2) => (
                    <td key={`loading-skeleton-col-${index}-${index2}`}><Skeleton /></td>
                  ))}
                </tr>
              ))}

            </tbody>
          </Table>
        </CardBody>
      </Card>
    </Row>
  );
}

LoadingSkeleton.propTypes = {
  rows: PropTypes.number,
  cols: PropTypes.number,
};

export default LoadingSkeleton;
