import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import withRouter from './common/withRouter';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from "reselect";
import { changeLayoutMode } from 'store/actions';
import { Navigate } from 'react-router-dom';
import { STORAGE_KEY } from 'helpers/cc_browser_storage_helper';

const NonAuthLayout = (props) => {
  const dispatch = useDispatch();

  const LayoutModeProperties = createSelector(
    (state) => state.Layout,
    (layout) => ({
      layoutModeType: layout.layoutModeType,
    })
  );

  const {
    layoutModeType
  } = useSelector(LayoutModeProperties);

  useEffect(() => {
    if (layoutModeType) {
      dispatch(changeLayoutMode(layoutModeType));
    }
  }, [layoutModeType, dispatch]);

  //This will fix the issue when user is logged-in and tries to go to non-auth routes like /login
  const userAuthenticated = localStorage.getItem(STORAGE_KEY.AUTH_TOKEN);
  const adminUserAuthenticated = localStorage.getItem(STORAGE_KEY.AUTH_ADMIN_USER);

  if (userAuthenticated || adminUserAuthenticated) {
    const redirectPath = userAuthenticated ? "/dashboard" : "/client-users";
    return <Navigate to={redirectPath} />;
  }
  return (
    <React.Fragment>{props.children}</React.Fragment>
  );
};

NonAuthLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object
};

export default withRouter(NonAuthLayout);
