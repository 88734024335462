import {
  GET_CLIENTS,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_ERROR,
  POST_ADMIN_VIEW_CLIENT_LOGIN,
  POST_ADMIN_VIEW_CLIENT_LOGIN_SUCCESS,
  POST_ADMIN_VIEW_CLIENT_LOGIN_ERROR
} from "./actionTypes"

export const getClients = (query_params) => {
    return {
      type: GET_CLIENTS,
      payload: query_params
    }
  }
  
  export const getClientsSuccess = (admin_users) => {
    return {
      type: GET_CLIENTS_SUCCESS,
      payload: admin_users
    }
  }
  
  export const getClientsError = error => {
    return {
      type: GET_CLIENTS_ERROR,
      payload: error,
    }
  }


export const postAdminViewClientLogin = (admin_view_client_login) => {
  return {
    type: POST_ADMIN_VIEW_CLIENT_LOGIN,
    payload: admin_view_client_login,
  }
}

export const postAdminViewClientLoginSuccess = admin_view_client_login => {
  return {
    type: POST_ADMIN_VIEW_CLIENT_LOGIN_SUCCESS,
    payload: admin_view_client_login,
  }
}

export const postAdminViewClientLoginError = error => {
  return {
    type: POST_ADMIN_VIEW_CLIENT_LOGIN_ERROR,
    payload: error,
  }
}
