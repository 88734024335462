import {
  LOGIN_ADMIN_USER,
  LOGIN_ADMIN_USER_SUCCESS,
  LOGOUT_ADMIN_USER,
  LOGOUT_ADMIN_USER_SUCCESS,
  API_ERROR,
  GET_ADMIN_USER_CURRENT_USER,
  GET_ADMIN_USER_CURRENT_USER_SUCCESS,
  GET_ADMIN_USER_CURRENT_USER_ERROR,
} from "./actionTypes"

const initialState = {
  error: false,
  loading: false,
  remember_me: false,
  current_user: null,
  current_admin_user_permissions: [],
  reload_required: false
}

const LoginAdminUser = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_ADMIN_USER:
      state = {
        ...state,
        error: false,
        loading: true,
      }
      break
    case LOGIN_ADMIN_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
        current_user: action.payload,
        current_admin_user_permissions: (action.payload?.admin_role?.admin_permissions || []).filter(perm => perm?.active).map(perm => perm?.admin_feature?.name)
      }
      break
    case LOGOUT_ADMIN_USER:
      state = { ...state, loading: true, error: null }
      break
    case LOGOUT_ADMIN_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false
      }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    case GET_ADMIN_USER_CURRENT_USER:
      state = {
        ...state,
        loading: true,
        error: null
      }
      break
    case GET_ADMIN_USER_CURRENT_USER_SUCCESS:
      state = {
        ...state,
        current_user: action.payload,
        current_admin_user_permissions: (action.payload?.admin_role?.admin_permissions || []).filter(perm => perm?.active).map(perm => perm?.admin_feature?.name),
        loading: false
      }
      break
    case GET_ADMIN_USER_CURRENT_USER_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload
      }
      break
    default:
      return state
  }
  return state
}

export default LoginAdminUser
