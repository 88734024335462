import { call, put, takeLatest } from "redux-saga/effects"

import {
  GET_CLIENTS,
  POST_ADMIN_VIEW_CLIENT_LOGIN
} from "./actionTypes"

import {
  getClientsSuccess,
  getClientsError
} from "./actions"

import { genericGetRequest_2 } from "helpers/backend_helper"

import { AppConfiguration } from "config"
import { bsh_getAdminUserDetails } from "helpers/cc_browser_storage_helper"
import {
  postAdminViewClientLoginSuccess,
  postAdminViewClientLoginError
} from "./actions"
import { postJwtLogin } from "helpers/backend_helper"
import { LOGIN_AS_USER_KEY } from "helpers/constants"


function* onGetClients(action) {
  try {
    let user = bsh_getAdminUserDetails();
    const response = yield call(
      genericGetRequest_2,
      `${AppConfiguration.RAILS_BACKEND_URL}/api/v1/admin_manage_clients`,
      { ...action.payload?.queryParams },
      {
        Authorization: user.token,
      }
    )
    yield put(getClientsSuccess(response))
  } catch (error) {
    yield put(getClientsError(error))
  }
}

function* onAdminViewClientLogin({ payload: { user, history, accountId } }) {
  try {
    let adminUserInfo = bsh_getAdminUserDetails();
    let data = {
      ...user,
      token: adminUserInfo.token
    }
    const response = yield call(
      postJwtLogin,
      `${AppConfiguration.RAILS_BACKEND_URL}/api/v1/login`,
      data
    )

    let authUser = {
      user: {
        token: response?.data?.token, account_id: response?.data?.account_id
      }
    } // TODO : check when API received
    yield put(postAdminViewClientLoginSuccess(response.data));
    const hostParts = window.location.host.split('.');
    const newHost = hostParts.map(part => (part.includes(AppConfiguration.REACT_ADMIN_HOST_SUBDOMAIN)) ? AppConfiguration.REACT_CLIENT_HOST_SUBDOMAIN : part).join('.');
    window.open(`http://${newHost}/login?${LOGIN_AS_USER_KEY.ADMIN_ACCESS}=true&${LOGIN_AS_USER_KEY.ACCESS_USER_TOKEN}=${JSON.stringify(authUser)}&${LOGIN_AS_USER_KEY.ACCESS_ADMIN_TOKEN}=${adminUserInfo.token}`, "_blank");
  } catch (error) {
    if (error) {
      history("/")
    }
    yield put(postAdminViewClientLoginError(error))
  }
}

function* clientsSaga() {
  yield takeLatest(GET_CLIENTS, onGetClients)
  yield takeLatest(POST_ADMIN_VIEW_CLIENT_LOGIN, onAdminViewClientLogin)
}

export default clientsSaga;
