import {
  LOGIN_ADMIN_USER,
  LOGIN_ADMIN_USER_SUCCESS,
  LOGOUT_ADMIN_USER,
  LOGOUT_ADMIN_USER_SUCCESS,
  API_ERROR,
  GET_ADMIN_USER_CURRENT_USER,
  GET_ADMIN_USER_CURRENT_USER_SUCCESS,
  GET_ADMIN_USER_CURRENT_USER_ERROR,

} from "./actionTypes"

export const loginAdminUser = (user, history) => {
  return {
    type: LOGIN_ADMIN_USER,
    payload: { user, history },
  }
}

export const loginAdminUserSuccess = user => {
  return {
    type: LOGIN_ADMIN_USER_SUCCESS,
    payload: user,
  }
}

export const logoutAdminUser = history => {
  return {
    type: LOGOUT_ADMIN_USER,
    payload: { history },
  }
}

export const logoutAdminUserSuccess = () => {
  return {
    type: LOGOUT_ADMIN_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const getAdminUserCurrentUser = (history) => {
  return {
    type: GET_ADMIN_USER_CURRENT_USER,
    payload: { history }
  }
}

export const getAdminUserCurrentUserSuccess = user => {
  return {
    type: GET_ADMIN_USER_CURRENT_USER_SUCCESS,
    payload: user
  }
}

export const getAdminUserCurrentUserError = error => {
  return {
    type: GET_ADMIN_USER_CURRENT_USER_ERROR,
    payload: error
  }
}
