export const LOGIN_ADMIN_USER = "LOGIN_ADMIN_USER"
export const LOGIN_ADMIN_USER_SUCCESS = "LOGIN_ADMIN_USER_SUCCESS"

export const LOGOUT_ADMIN_USER = "LOGOUT_ADMIN_USER"
export const LOGOUT_ADMIN_USER_SUCCESS = "LOGOUT_ADMIN_USER_SUCCESS"

export const API_ERROR = "API_ERROR"

export const GET_ADMIN_USER_CURRENT_USER = "GET_ADMIN_USER_CURRENT_USER"
export const GET_ADMIN_USER_CURRENT_USER_SUCCESS = "GET_ADMIN_USER_CURRENT_USER_SUCCESS"
export const GET_ADMIN_USER_CURRENT_USER_ERROR = "GET_ADMIN_USER_CURRENT_USER_ERROR"