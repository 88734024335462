import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Routes, Route, useNavigate } from "react-router-dom";
import { layoutTypes } from "./constants/layout";
import 'assets/css/style.css';

// Import Routes all
import { authProtectedRoutes, publicRoutes, adminPublicRoutes, adminUserAuthProtectedRoutes } from "./routes";
import { jwtDecode } from 'jwt-decode';
import useClearableTimeout from 'hooks/useClearableTimeout';
import './assets/scss/theme.scss';
import './assets/scss/datatables.scss';
import './assets/css/table_header.css'
import Authmiddleware from "./routes/route";
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import { bsh_getUserAuthToken, bsh_setAdminUserDetails, STORAGE_KEY } from 'helpers/cc_browser_storage_helper';
import { AppConfiguration } from 'config';
import { LOGIN_AS_USER_KEY } from 'helpers/constants';

const getLayout = (layoutType) => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout;
      break;
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout;
      break;
    default:
      break;
  }
  return Layout;
};

const App = () => {
  const [userAuthToken, setUserAuthToken] = useState(null);

  const selectLayoutState = (state) => state.Layout;
  const LayoutProperties = createSelector(
    selectLayoutState,
    (layout) => ({
      layoutType: layout.layoutType,
    })
  );

  const authToken = bsh_getUserAuthToken();
  if (authToken != userAuthToken) {
    setUserAuthToken(authToken);
  }
  const navigate = useNavigate();
  const [customSetTimeout] = useClearableTimeout();

  const removeTokenAndNavigate = () => {
    setUserAuthToken(null)
    localStorage.removeItem(STORAGE_KEY.AUTH_TOKEN);
  };

  const urlParams = new URLSearchParams(location.search);
  const accessUserToken = urlParams.get(LOGIN_AS_USER_KEY.ACCESS_USER_TOKEN)
  const adminAccess = urlParams.get(LOGIN_AS_USER_KEY.ADMIN_ACCESS)
  const accessAdminToken = urlParams.get(LOGIN_AS_USER_KEY.ACCESS_ADMIN_TOKEN)

  useEffect(() => {
    if (accessUserToken) {
      const parsedToken = JSON.parse(accessUserToken);
      localStorage.setItem(STORAGE_KEY.AUTH_TOKEN,  parsedToken?.user?.token);
      adminAccess != null && localStorage.setItem(STORAGE_KEY.ADMIN_ACCESS, adminAccess);
      bsh_setAdminUserDetails({ token: accessAdminToken });
    }
  }, [accessUserToken]);

  useEffect(() => {
    if (userAuthToken) {
      try {
        const decodedToken = jwtDecode(authToken);
        const currentTime = Date.now();
        const timeUntilExpiry = decodedToken.exp * 1000 - currentTime;

        if (timeUntilExpiry > 0) {
          customSetTimeout(removeTokenAndNavigate, timeUntilExpiry);
        } else {
          removeTokenAndNavigate();
        }
      } catch (error) {
        removeTokenAndNavigate();
      }
    }
  }, [navigate, userAuthToken]);

  const {
    layoutType
  } = useSelector(LayoutProperties);

  const Layout = getLayout(layoutType);

  const parts = window.location.hostname.split('.').filter(part => part !== 'www');
  const is_admin = !!parts.find((part) => part.includes(AppConfiguration.REACT_ADMIN_HOST_SUBDOMAIN));

  const routes = is_admin
    ? [...adminPublicRoutes, ...adminUserAuthProtectedRoutes]
    : [...publicRoutes, ...authProtectedRoutes];
  return (
    <React.Fragment>
      <Routes>
        {routes.map((route, idx) => {
          return (
            <Route
              path={route.path}
              element={
                route?.isAuthProtected ? (
                  <Authmiddleware>
                    <Layout>{route.component}</Layout>
                  </Authmiddleware>
                ) : (
                  <NonAuthLayout>{route.component}</NonAuthLayout>
                )
              }
              key={idx}
              exact={true}
            />
          );
        })}

      </Routes>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

export default App;
