import { errorMessages } from 'common/utils/data_validations';
import { STORAGE_KEY } from 'helpers/cc_browser_storage_helper';

export const percentageIncrease = (oldVal, newVal) => {
  return oldVal ? ((newVal - oldVal) / oldVal) * 100.0 : 0;
}

export const STATUS_OPTIONS = {
  ACTIVE: "active",
  PAUSED: "paused",
  ONBOARDING: "onboarding",
  INACTIVE: "inactive",
  REGISTRATION_PENDING: 'registration_pending'
}

export const capitalizeWord = (word) => {
  if (word && typeof word === 'string') {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }
  return '';
};

export const handleSessionTimeOut = (error) => {
  if (error && error === errorMessages.ERR_DEVISE_SIGNATURE_HAS_EXPIRED) {
    localStorage.removeItem(STORAGE_KEY.AUTH_TOKEN);
    window.location = '/login?session_expired=1';
  }
};
